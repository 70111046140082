import { IUser, Permission } from "@cms/core/endpoints/services/models";

/** Represents sign-in credentials with email and password */
export interface ISignInCredentials {
  email: string,
  password: string,
}

export interface IUserInfo extends IUser {
  userName?: string;
  permissions?: Permission[];
}
