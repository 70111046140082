import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { UtilitiesService } from '@cms/core/services';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'cms-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})

export class LoadingIndicatorComponent implements OnInit {

  public message: boolean = false;
  public loading: number = 0;
  public loaderPath: string = '/assets/images/loader.png';

  private loadingId: string = '';

  private subscriptionsList: Subscription[] = [];

  constructor(
    private readonly utilities: UtilitiesService,
    private messageService: NzMessageService,
  ) {

    this.loaderPath = this.utilities.getLocalizedAbsolutePath(this.loaderPath);

  }

  /**
  * Check state of loader.
  */
  public ngOnInit(): void {

    // Use if "DELAY" because otherwise it complains about expression-change-after-init
    const messageSubscription = this.utilities.loader.pipe(delay(1)).subscribe(message => {

      if (message) {

        this.message = message;

        this.loading++;

        if (this.loadingId == '') {

          this.loadingId = this.messageService.loading('Loading..', { nzDuration: 0 }).messageId;

        }

      }
      else {

        if (this.loading > 0) {

          this.loading--;

          if (this.loading === 0) {

            this.message = false;

            if (this.loadingId !== '') {

              this.messageService.remove(this.loadingId);
              this.loadingId = '';

            }

          }

        }

      }

    });

    this.subscriptionsList.push(messageSubscription);

  }

  /**
  * Unsubscribe the subscription.
  */
  public ngOnDestroy(): void {

    this.subscriptionsList.forEach(one => one.unsubscribe());

  }
}
