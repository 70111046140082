import { Injectable } from '@angular/core';

/** Service to manage browser local storage */
@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  /**
   * Sets item in local storage
   * 
   * @param key Local storage item key
   * @param item Local storage item
   */
  set<T>(key: string, item: T): void {

    localStorage.setItem(key, JSON.stringify(item))

  }

  /**
   * Gets item from local storage
   * 
   * @param key Local storage item key
   * @returns Local storage item parsed in specified type
   */
  get<T>(key: string): T {

    return <T>JSON.parse(localStorage.getItem(key) as string);

  }

  /**
   * Removes item from local storage
   * 
   * @param key Local storage item key
   */
  remove(key: string): void {

    return localStorage.removeItem(key);

  }

}
