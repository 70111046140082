import { Component } from '@angular/core';
import { AuthService } from './core/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'admin';
  isLoggedIn:boolean = false;

  constructor(
    private authService: AuthService,
  ){
    this.isLoggedIn = this.authService.isLogged();
    this.authService.isUserLoggedIn$.subscribe((value) => {
      this.isLoggedIn = value;
    });
  }
}
