import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { IErrorResponse } from '@cms/core/endpoints/models'
import { catchError, Observable } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { EnvironmentService, AuthService } from '@cms/core/services';
import { Router } from '@angular/router';

/** Interceptor for intercepting and handling HTTP errors. */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {


  constructor(
    private authService: AuthService,
    private nzNotificationService: NzNotificationService,
    private environmentService: EnvironmentService,
    private router: Router
  ) { }

  /** Intercept and handles HTTP errors */
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {

          const errorResponse: IErrorResponse = error.error.response || error;

          if ((error.status == 401 || error.status == 403)) {

            this.nzNotificationService.create(
              "error",
              'Unauthorized Request',
              errorResponse.error
            );

            this.authService.logoutUser();

            this.router.navigate(['/login']);

          }
          else if(errorResponse?.error){
            this.nzNotificationService.create(
              "error",
              'ERROR',
              errorResponse.error || 'Something went wrong!'
            );

          }

          console.error("Error intercepted", error);

        throw error;

      })
    );

  }

}
