<div class="control-container">
  <div class="control-title mb-3 fw-bold d-flex justify-content-between">
    <div>
      <i nz-icon [nzType]="'align-left'" nzTheme="outline" class="ant-btn-primary p-2 rounded-circle"></i>
      Content-Block{{ currentIndex > 0 ? ' - ' + currentIndex : ''}}
    </div>
    <div>
      <nz-button-group>
        <button *ngIf="currentIndex > 1" nz-button nzType="primary" nz-tooltip nzTooltipTitle="Move Up"
          nzTooltipPlacement="bottom" (click)="reIndexControl(currentIndex-1, currentIndex-2)"><i nz-icon
            nzType="arrow-up"></i></button>
        <button *ngIf="currentIndex < controlLength" nz-button nzType="primary" nz-tooltip nzTooltipTitle="Move Down"
          nzTooltipPlacement="bottom" (click)="reIndexControl(currentIndex-1, currentIndex)"><i nz-icon
            nzType="arrow-down"></i></button>
        <button *ngIf="controlLength > 1" nz-button nzType="primary" nz-tooltip nzTooltipTitle="Re-Index Position"
          nzTooltipPlacement="bottom" nz-popover nzPopoverTitle="" nzPopoverTrigger="click"
          [nzPopoverContent]="contentTemplate"><i nz-icon nzType="retweet"></i></button>
        <button nz-button nzType="primary" (click)="delete()"><i nz-icon nzType="delete"></i></button>
      </nz-button-group>
      <ng-template #contentTemplate>

        <nz-input-group nzSearch [nzAddOnAfter]="addOnAfterTemplate">
          <nz-input-number [(ngModel)]="moveTo" [nzMin]="1" [nzMax]="controlLength" [nzStep]="1"></nz-input-number>
        </nz-input-group>
      </ng-template>
      <ng-template #addOnAfterTemplate>
        <button nz-button nzType="primary" nzSearch (click)="reIndexControl(currentIndex-1, moveTo-1)">Move</button>
      </ng-template>
    </div>
  </div>
  <div nz-row [nzGutter]="16">
    <div nz-col class="gutter-row" nzSpan="12">
      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="contentBlockHeader">Header</nz-form-label>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter the header text!">
          <input nz-input id="contentBlockHeader" [(ngModel)]="contentBlockHeaderText" (change)="onChangeHeaderText()"/>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col class="gutter-row" nzSpan="12">
      <div nz-row [nzGutter]="24">
        <div nz-col class="gutter-row" nzSpan="12">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="contentBlockHeaderBgColor">Header Background Color
            </nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24">
              <nz-avatar id="contentBlockHeaderBgColor" [nzGap]="4"
                [ngStyle]="{ 'background-color': contentBlockHeaderBackgroundColor }"
                style="vertical-align: middle;cursor: pointer;" [(colorPicker)]="contentBlockHeaderBackgroundColor"
                (colorPickerChange)="onChangeHeaderBgColor($event)">
              </nz-avatar>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col class="gutter-row" nzSpan="6">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="contentBlockHeaderTextColor">Header Text Color
            </nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24">
              <nz-tag id="contentBlockHeaderTextColor" [nzColor]="contentBlockHeaderBackgroundColor"
                [ngStyle]="{ 'background-color': contentBlockHeaderBackgroundColor, 'color': contentBlockHeaderTextColor}"
                [(colorPicker)]="contentBlockHeaderTextColor" (colorPickerChange)="onChangeHeaderTextColor($event)">
                Header Text
              </nz-tag>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </div>
  <div class="control-body">
    <div [attr.id]="id"></div>
  </div>
</div>
