import { EventEmitter, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ISignInCredentials } from '@cms/shared/models';
import { IUser } from '@cms/core/endpoints/services/models';
import { IResponse, IErrorResponse } from '@cms/core/endpoints/models';
import { LocalStorageService } from '@cms/core/services';
import { AuthService as GumsAuthService } from 'src/app/core/endpoints/services';
import { RequestParamsService as GumsRequestParamsService } from 'src/app/core/endpoints/services';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { IUserInfo } from '@cms/shared/models';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';

/** Service for managing authentication of application. */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /** Activated route query params */
  private urlQueryParams: Params = {};

  /** Key for storing credentials in local storage */
  private type: string = 'cms-admin';

  /** URL for redirection after login */
  private redirectAfterLogin: string = '';

  public isUserLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private loggedInUser: IUserInfo;

  constructor(
    private gumsAuthService: GumsAuthService,
    private gumsRequestParamsService: GumsRequestParamsService,
    private localStorageService: LocalStorageService,
    private nzNotificationService: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private nzModalService: NzModalService,
  ) {

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.urlQueryParams = params
    });

    this.loggedInUser = this.getLoggedInUser();

    if (this.loggedInUser) {

      this.isUserLoggedIn$.next(true);

      this.gumsRequestParamsService.setAccessToken(this.loggedInUser.accessToken);

    }

  }

  /**
   * Authenticate User and sets the user in application.
   *
   * @param signInCredentials sign-in credentials with email and password.
   * @returns An Observable of boolean, determines whether the user is logged in or not.
   */
  public loginUser(signInCredentials: ISignInCredentials): Observable<boolean> {

    return this.gumsAuthService.loginUser(signInCredentials).pipe(map((response: IResponse<IUser>) => {

      if (response.status === 'ok') {
        const user: IUser = response.data;
        const queryParams: Params = {};

        this.setCredentials(user);

        this.router.navigate(['/collections/pages'], { queryParams: queryParams });

      }

      return true;

    }),
      catchError((httpErrorResponse: HttpErrorResponse) => {

        const error: IErrorResponse = httpErrorResponse.error as IErrorResponse;

        if (error.error) throw error.error;

        else return of(false);

      }),
    );
  }

  /**
   * Merges Client data and User data then sets it into local storage.
   * Also track how you associate your users and their actions to a recognizable userId and traits.
   *
   * @param user User data form SocialMedia API
   * @param client Client data from SocialMedia API
   *
   * @param userId — The database ID for the user.
   * @param traits — A dictionary of traits you know about the user, like their email or name
   * @param options — A dictionary of options.
   */
  private setCredentials(user: IUser) {

    const userInfo: IUserInfo = {
      ...user
    }

    this.gumsRequestParamsService.setAccessToken(userInfo.accessToken);

    this.localStorageService.set<IUserInfo>(this.type, userInfo)
    this.loggedInUser = userInfo;

    this.isUserLoggedIn$.next(true);

  }

  /**
   * Checks if user is logged in or not.
   *
   * @returns True if user is logged in, false otherwise.
   */
  public isLogged(): boolean {

    const userFound = this.localStorageService.get<IUserInfo>(this.type);

    return (userFound && userFound.hasLoggedIn);

  }

  /**
   * Gets the user info stored in local storage
   *
   * @returns Logged in user info
   */
  public getLoggedInUser(): IUserInfo {

    this.loggedInUser = this.loggedInUser || this.localStorageService.get<IUserInfo>(this.type);

    return this.loggedInUser;

  }

  /**
   * Logout User and clear credentials
   *
   * @param withoutSendingRequest True for logging out by sending request, false otherwise.
   */
  public logoutUser(withoutSendingRequest?: boolean): void {

    this.clearCredentials();

    this.isUserLoggedIn$.next(false);

  }

  /** Clears credentials */
  private clearCredentials(): void {

    this.loggedInUser = null as any

    this.localStorageService.remove('temp');
    this.localStorageService.remove(this.type);
    this.gumsRequestParamsService.setAccessToken('');

  }

  /**
   * Updated logged in user information
   *
   * @param userInfo User information that has to be updated
   */
  public updateLoggedInUser(userInfo: IUserInfo): void {

    Object.assign(this.loggedInUser, userInfo);

    this.localStorageService.set<IUserInfo>(this.type, this.loggedInUser);

  }

}

