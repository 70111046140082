import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EnvironmentService } from '@cms/core/services';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  /**It holds the default date format */
  private readonly defaultFormat = 'MM/dd/yyyy, hh:mm a';

  constructor(
    private environment: EnvironmentService,
  ) {}

  public loader: Subject<boolean> = new Subject();

  /**
  * Localizes an absolute path by prefixing the given path with the appropriate locale ID.
  * @param path An absolute path to localize (e.g. /assets/loader.png).
  * @returns Localized absolute path.
  */
  public getLocalizedAbsolutePath(path: string): string {

    // If app is being served using "ng serve", ignore the localization
    if (this.environment.ENVIRONMENT === 'local') return `/${path.replace(/^\/+/, '')}`;

    return `/${this.environment.currentLocale}/${path.replace(/^\/+/, '')}`;

  }

  public get defaultDateFormat(): string {

    return this.defaultFormat;

  }

}
