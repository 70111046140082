import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[pfNumberOnly]'
})
export class NumberOnlyDirective {

  constructor(private elementRef: ElementRef) {

    this.elementRef.nativeElement.onkeypress = this.onKeyPress;

  }

  onKeyPress(event: any): boolean {

    const charCode = (event.which) ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;

    else return true;

  }

}

