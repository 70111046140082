<nz-layout class="main-layout">
  <nz-header>
    <!-- <div class="logo">Public</div> -->
  </nz-header>
  <nz-content class="public-content">
    <div class="inner-content">
      <router-outlet></router-outlet>
    </div>
  </nz-content>
  <nz-footer>CMS Admin ©2020</nz-footer>
</nz-layout>
