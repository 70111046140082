<nz-layout>
  <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null" class="shadow-sm">
    <div class="logo"></div>
    <ul nz-menu nzTheme="light" nzMode="inline">
      <li nz-submenu nzOpen nzTitle="Collection Types" nzIcon="group">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a [routerLink]="['/collections/pages']">
              <i nz-icon nzType="profile"></i>
              <span>Pages</span>
            </a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a [routerLink]="['/collections/users']">
              <i nz-icon nzType="user"></i>
              <span>Users</span>
            </a>
          </li>
        </ul>
      </li>
      <li nz-submenu nzOpen nzTitle="Plugins" nzIcon="bars">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a [routerLink]="['/plugins/media']">
              <i nz-icon nzType="cloud-upload"></i>
              <span>Media Library</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header class="shadow-sm">
      <div nz-row nzJustify="space-between">
        <div nz-col>
          <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
            (click)="isCollapsed = !isCollapsed"></i>
        </div>
        <div nz-col>
          <button class="fw-bold primary-color" nz-button nzType="text" nzSize="large" (click)="onLogout()">
            <i nz-icon nzType="logout"></i>
            Logout
          </button>
        </div>
      </div>
    </nz-header>
    <nz-content>
      <router-outlet></router-outlet>
    </nz-content>
    <nz-footer>Healthy.org ©{{today | date:'yyyy'}}</nz-footer>
  </nz-layout>
</nz-layout>
