import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestParamsService } from 'src/app/core/endpoints/services';
import { IUser } from '@cms/core/endpoints/services/models';
import { ISignInCredentials } from '@cms/shared/models';
import { IResponse, IRequestParams } from '@cms/core/endpoints/models';
import { map, Observable } from 'rxjs';
import { LocalStorageService } from '@cms/core/services';

/** A service which communicates with API endpoints for user authentication purpose.*/
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private requestParamsService: RequestParamsService,
    private localStorageService: LocalStorageService,
    private http: HttpClient
  ) { }

  /**
   * Sends request to API for user login.
   *
   * @param signInCredentials sign-in credentilas with email and password.
   * @returns An Observable of the response, with logged in user.
   */
  public loginUser(signInCredentials: ISignInCredentials): Observable<IResponse<IUser>> {

    const requestParams: IRequestParams<URLSearchParams> = this.requestParamsService.login(signInCredentials);

    const httpOptions = {
      headers: new HttpHeaders(requestParams.headers),
    };

    return this.http.post(requestParams.url, requestParams.body, httpOptions).pipe(map((response) => {
      return response as IResponse<IUser>;
    }))

  }

}
