import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IMediaAttachment, IParams, IRequestParams, IResponse } from '../models';
import { IPage } from './models';
import { RequestParamsService } from './request-params.service';

@Injectable({
  providedIn: 'root'
})
export class MediaLibraryService {

  constructor(
    private http: HttpClient,
    private requestParamsService: RequestParamsService,
  ) { }

  /**
   * Sends request to the API to upload the selected file.
   *
   * @returns Observable of IResponse having response.
   */
  public uploadFiles(file: File, fileName:string): Observable<IResponse<any>> {

    const requestParams: IRequestParams<null | URLSearchParams> = this.requestParamsService.uploadFiles();

    const formData = new FormData();

      if(file)
        formData.append('file', file, fileName);

    const httpOptions = {
      headers: new HttpHeaders(requestParams.headers),
      params: requestParams.params,
    };

    return this.http.post(requestParams.url, formData, httpOptions).pipe(map((response) => {
      return response as IResponse<any>;
    }))

  }

  /**
   * Sends request to the API to get the list of media attachements.
   *
   * @returns Observable of IResponse having IMediaAttachment.
   */
  public getMediaAttachments(): Observable<IResponse<IMediaAttachment[]>> {

    const requestParams: IRequestParams<URLSearchParams> = this.requestParamsService.getMediaAttachments();

    const httpOptions = {
      headers: new HttpHeaders(requestParams.headers),
      params: requestParams.params,
    };

    return this.http.get(requestParams.url, httpOptions).pipe(map((response) => {
      return response as IResponse<IMediaAttachment[]>;
    }))

  }

  /**
   * Sends request to the API to delete the selected attachment.
   *
   * @returns Observable of IResponse having id of the deleted attachment.
   */
  public deleteAttachment(payload:IParams): Observable<IResponse<string>> {

    const requestParams: IRequestParams<URLSearchParams> = this.requestParamsService.deleteAttachment(payload);

    const httpOptions = {
      headers: new HttpHeaders(requestParams.headers),
      params: requestParams.params,
    };

    return this.http.delete(requestParams.url, httpOptions).pipe(map((response) => {
      return response as IResponse<string>;
    }))

  }

}
