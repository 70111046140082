import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cms-no-records-found',
  templateUrl: './no-records-found.component.html',
  styleUrls: ['./no-records-found.component.scss']
})
export class NoRecordsFoundComponent implements OnInit {

  /** Input for title property */
  @Input() title: string | undefined;

  /** Input for description property */
  @Input() description: string | undefined;

  /** Input for buttonText property */
  @Input() buttonText: string | undefined;

  /** Output for onAction  event */
  @Output() onAction  = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public emitAction(): void {

    this.onAction .emit(true)
  }

  /**
   * Emits event on button click
   */
  public emitEvent(): void {
    this.onAction .emit(true)
  }

}
