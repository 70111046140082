import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@cms/shared/guards';
import { AuthModuleGuard } from '@cms/shared/guards';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'collections',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(module => module.DashboardModule),
    data: {
      title: 'Dashboard'
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'collections',
    loadChildren: () => import('./collection-types/collection-types.module').then(module => module.CollectionTypesModule),
    data: {
      title: 'Collection Types'
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'plugins',
    loadChildren: () => import('./plugins/plugins.module').then(module => module.PluginsModule),
    data: {
      title: 'Plugins'
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then(module => module.AuthModule),
    data: {
      title: 'Login'
    },
    canActivate: [AuthModuleGuard]
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full',
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
