/** Enum for API response status */
export enum ResponseStatus {
  OK = 'ok',
  ERROR = 'error',
}

/** Enum for add section controls */
export enum AddSectionControlNames {
  ContentBlock = 'CONENT_BOLOCK',
  CardBlock = 'CARD_BLOCK',
  ListBlock = 'LIST_BLOCK',
}

/** Represents dropdown/select option */
export interface IOption<T> {
  value: T;
  label: string;
  selected?: boolean;
  range?: string;
  icon: string;
}


export const addSectionControlOptions: IOption<AddSectionControlNames>[] = [
  {
    value: AddSectionControlNames.ContentBlock,
    label: 'Content Block',
    icon: 'align-left',
  },
  {
    value: AddSectionControlNames.CardBlock,
    label: 'Card Block',
    icon: 'credit-card',
  },
  {
    value: AddSectionControlNames.ListBlock,
    label: 'List Block',
    icon: 'ordered-list',
  },
]

export interface ICardBlock {
  cardBlockHeader: string;
  cardBlockHeaderBgColor: string;
  cardBlockBgColor: string;
  cardBlockColumns: string;
  cards: ICardControl[];
}

export interface ICardControl {
  imagePath: string;
  header: string;
  cardLink: string;
  description: string;
  bgColor:string;
}

export interface IListControl{
  listBlockHeader: string;
  listBlockHeaderBgColor: string;
  listBlockHeaderTextColor: string;
  items: Array<IListControlItems>;
}

export interface IListControlItems{
  title: string;
  link: string;
}

export interface IContentBlockAttributes{
  contentBlockHeaderText:string;
  contentBlockHeaderBGColor: string;
  contentBlockHeaderTextColor: string;
  content: string;
}
