// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  PORT: 2022,
  BASE: "https://testadmin.healthy.org.nz",
  ENVIRONMENT: "local",
  API_SERVICE_URL: "https://testcmsapi.healthy.org.nz/api",
  FRONTEND_BASE_URL: "https://testcms.healthy.org.nz",

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
