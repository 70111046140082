import { NgModule } from '@angular/core';
import { CommonModule ,registerLocaleData} from '@angular/common';
import { RouterModule } from '@angular/router';
import { ClipboardModule } from "@angular/cdk/clipboard";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzTagModule } from 'ng-zorro-antd/tag';

import { ColorPickerModule } from 'ngx-color-picker';

import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { NoRecordsFoundComponent } from './components/no-records-found/no-records-found.component';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { ContentBlocksComponent } from './components/content-blocks/content-blocks.component';
import { CtrlContentBlockComponent } from './components/section-controls/ctrl-content-block/ctrl-content-block.component';
import { CtrlCardBlockComponent } from './components/section-controls/ctrl-card-block/ctrl-card-block.component';
import { BrowseImageComponent } from './components/browse-image/browse-image.component';
import { TimesAgoPipe } from './pipes/times-ago.pipe';
import { CtrlListBlockComponent } from './components/section-controls/ctrl-list-block/ctrl-list-block.component';

@NgModule({
  declarations: [
    LoadingIndicatorComponent,
    NoRecordsFoundComponent,
    NumberOnlyDirective,
    ContentBlocksComponent,
    CtrlContentBlockComponent,
    CtrlCardBlockComponent,
    BrowseImageComponent,
    TimesAgoPipe,
    CtrlListBlockComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    ClipboardModule,

    NzModalModule,
    NzButtonModule,
    NzIconModule,
    NzGridModule,
    NzInputModule,
    NzSpinModule,
    NzMessageModule,
    NzSelectModule,
    NzFormModule,
    NzCardModule,
    NzListModule,
    NzImageModule,
    NzToolTipModule,
    NzPopoverModule,
    NzInputNumberModule,
    NzAvatarModule,
    NzTagModule,

    ColorPickerModule,
  ],
  exports: [
    LoadingIndicatorComponent,
    NoRecordsFoundComponent,
    NumberOnlyDirective,
    ContentBlocksComponent,
    CtrlContentBlockComponent,
    CtrlCardBlockComponent,
    BrowseImageComponent,
    TimesAgoPipe,
    CtrlListBlockComponent,
  ],
})
export class SharedModule { }
