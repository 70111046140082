<div class="control-container">
  <div class="control-title mb-3 fw-bold d-flex justify-content-between">
    <div>
      <i nz-icon [nzType]="'align-left'" nzTheme="outline" class="ant-btn-primary p-2 rounded-circle"></i>
      List-Block{{ currentIndex > 0 ? ' - ' + currentIndex : ''}}
    </div>
    <div>
      <nz-button-group>
        <button *ngIf="currentIndex > 1" nz-button nzType="primary" nz-tooltip nzTooltipTitle="Move Up"
          nzTooltipPlacement="bottom" (click)="reIndexControl(currentIndex-1, currentIndex-2)"><i nz-icon
            nzType="arrow-up"></i></button>
        <button *ngIf="currentIndex < controlLength" nz-button nzType="primary" nz-tooltip nzTooltipTitle="Move Down"
          nzTooltipPlacement="bottom" (click)="reIndexControl(currentIndex-1, currentIndex)"><i nz-icon
            nzType="arrow-down"></i></button>
        <button *ngIf="controlLength > 1" nz-button nzType="primary" nz-tooltip nzTooltipTitle="Re-Index Position"
          nzTooltipPlacement="bottom" nz-popover nzPopoverTitle="" nzPopoverTrigger="click"
          [nzPopoverContent]="listTemplate"><i nz-icon nzType="retweet"></i></button>
        <button nz-button nzType="primary" (click)="delete()"><i nz-icon nzType="delete"></i></button>
      </nz-button-group>
      <ng-template #listTemplate>
        <nz-input-group nzSearch [nzAddOnAfter]="addOnAfterTemplate">
          <nz-input-number [(ngModel)]="moveTo" [nzMin]="1" [nzMax]="controlLength" [nzStep]="1"></nz-input-number>
        </nz-input-group>
      </ng-template>
      <ng-template #addOnAfterTemplate>
        <button nz-button nzType="primary" nzSearch (click)="reIndexControl(currentIndex-1, moveTo-1)">Move</button>
      </ng-template>
    </div>
  </div>
  <div class="control-body">
    <div [attr.id]="id">
      <form nz-form [formGroup]='listForm'>
        <div nz-row [nzGutter]="16">
          <div nz-col class="gutter-row" nzSpan="12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzFor="listBlockHeader">Header</nz-form-label>
              <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter list header!">
                <input nz-input formControlName="listBlockHeader" id="listBlockHeader" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col class="gutter-row" nzSpan="12">
            <div nz-row [nzGutter]="24">
              <div nz-col class="gutter-row" nzSpan="12">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24" nzFor="listBlockHeaderBgColor">Header Background Color
                  </nz-form-label>
                  <nz-form-control [nzSm]="24" [nzXs]="24">
                    <nz-avatar id="listBlockHeaderBgColor" [nzGap]="4"
                      [ngStyle]="{ 'background-color': listBlockHeaderBackgroundColor }"
                      style="vertical-align: middle;cursor: pointer;" [(colorPicker)]="listBlockHeaderBackgroundColor"
                      (colorPickerChange)="onChangeHeaderBgColor($event)">
                    </nz-avatar>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col class="gutter-row" nzSpan="6">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24" nzFor="listBlockHeaderTextColor">Header Text Color
                  </nz-form-label>
                  <nz-form-control [nzSm]="24" [nzXs]="24">
                    <nz-tag id="listBlockHeaderTextColor" [nzColor]="listBlockHeaderBackgroundColor"
                      [ngStyle]="{ 'background-color': listBlockHeaderBackgroundColor, 'color': listBlockHeaderTextColor}"
                      [(colorPicker)]="listBlockHeaderTextColor" (colorPickerChange)="onChangeHeaderTextColor($event)">
                      Header Text
                    </nz-tag>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
        </div>
        <div nz-row [nzGutter]="16">
          <div nz-col class="gutter-row" [nzSpan]="24">
            <nz-list>
              <!-- <nz-list-header>List Items</nz-list-header> -->
              <nz-list-item formArrayName="items" *ngFor="let card of getListFormArrayControls(); let i = index">
                <ng-container [formGroupName]="i">
                  <div nz-row [nzGutter]="16" [style]="{'width':'calc(100% - 75px)'}">
                    <div nz-col class="gutter-row" [style]="{'width':'30px'}">
                      <nz-avatar [nzText]="(i + 1)+''" nzSize="small"
                        style="vertical-align: middle;background-color: #1890ff;"></nz-avatar>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="10">
                      <nz-form-item [style]="{'margin-bottom':'0'}">
                        <!-- <nz-form-label [nzSpan]="24" [nzFor]="'title'+i">Title
                        </nz-form-label> -->
                        <nz-form-control [nzSm]="24" [nzXs]="24">
                          <input nz-input formControlName="title" [attr.id]="'title'+i" placeholder="Title" />
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="10">
                      <nz-form-item [style]="{'margin-bottom':'0'}">
                        <!-- <nz-form-label [nzSpan]="24" [nzFor]="'link'+i">Link
                        </nz-form-label> -->
                        <nz-form-control [nzSm]="24" [nzXs]="24">
                          <input nz-input formControlName="link" [attr.id]="'link'+i" placeholder="Link" />
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </div>
                  <ul nz-list-item-actions>
                    <nz-list-item-action><a (click)="removeListItem(i)">
                        <i nz-icon nzType="delete"></i>
                      </a>
                    </nz-list-item-action>
                  </ul>
                </ng-container>
              </nz-list-item>
              <div class="loadmore" nz-list-load-more>
                <button nz-button nzType="primary" (click)="onAddMoreListItems()">
                  <i nz-icon nzType="plus" nzTheme="outline"></i>
                  Add More Items
                </button>
              </div>
            </nz-list>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
