import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@cms/core/services';
import { Observable } from 'rxjs';

/**
 * Guards the AuthModule until user is logged out.
 * Navigates to dashboard if user is logged in, else activates AuthModule.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthModuleGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.authService.isLogged()) {
      this.router.navigate(['/']);
    }

    return true;

  }

}
