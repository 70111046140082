import { Injectable } from '@angular/core';
import { EnvironmentService } from '@cms/core/services';
import { ISignInCredentials } from '@cms/shared/models';
import { IParams, IRequestParams } from '@cms/core/endpoints/models';
import { IFormValue, IPage } from './models';


/** A service which provides request params (i.e. request url, headers etc.) */
@Injectable({
  providedIn: 'root'
})
export class RequestParamsService {

  /** Current access token for API requests */
  private currentToken: string = '';

  /** Sets current access token for API requests */
  public setAccessToken(token: string): void {
    this.currentToken = token;
  }

  constructor(
    private environmentService: EnvironmentService
  ) { }

  /**
   * Generates the API query params.
   *
   * @param params existing query params
   * @returns Returns the API query params
   */
  private buildQueryParams(params?: IParams): IParams {

    params = params || {}

    return params;

  }

  /**
   * Generate request params for user login
   *
   * @returns request params for user login
   */
  public login(signInCredentials: ISignInCredentials): IRequestParams<URLSearchParams> {

    const body: URLSearchParams = new URLSearchParams(Object.entries(signInCredentials));

    return {
      url: `${this.environmentService.API_SERVICE_URL}/login`,
      body: body,
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    }

  }

  /**
   * Generates the API request params to fetch Users
   *
   * @returns API request params to fetch users
   */
  public getUsers(): IRequestParams<URLSearchParams> {

    return {
      url: `${this.environmentService.API_SERVICE_URL}/users`,
      headers: {
        'token': this.currentToken,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params: this.buildQueryParams(),
    }
  }

  /**
   * Generate the API request params for adding new user
   *
   * @param userData new user data
   *
   * @returns the API request params to add new user
   */
   public addUser(userData: IParams): IRequestParams<URLSearchParams> {

    const body: URLSearchParams = new URLSearchParams(Object.entries(userData));

    return {
      url: `${this.environmentService.API_SERVICE_URL}/register`,
      headers: {
        'token': this.currentToken,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      params: this.buildQueryParams(),
      body: body
    }

  }

  /**
   * Generate the API request params for updating a user
   *
   * @param id user id
   * @param userData user data
   *
   * @returns the API request params to updating a user
   */
   public updateUser(id:string, userData: IParams): IRequestParams<URLSearchParams> {

    const body: URLSearchParams = new URLSearchParams(Object.entries(userData));

    const params = {
      id: id
    } as IParams;

    return {
      url: `${this.environmentService.API_SERVICE_URL}/update/user`,
      headers: {
        'token': this.currentToken,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      params: this.buildQueryParams(params),
      body: body
    }

  }

  /**
   * Generate the API request params get a user
   *
   * @param id user id
   *
   * @returns the API request params get a user
   */
   public getUser(id:string): IRequestParams<URLSearchParams> {

    const params = {
      id: id
    } as IParams;

    return {
      url: `${this.environmentService.API_SERVICE_URL}/user`,
      headers: {
        'token': this.currentToken,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      params: this.buildQueryParams(params),
    }

  }

  /**
   * Generates the API request params to fetch Pages
   *
   * @returns API request params to fetch Pages
   */
  public getPages(payload:IParams): IRequestParams<URLSearchParams> {

    return {
      url: `${this.environmentService.API_SERVICE_URL}/pages`,
      headers: {
        'token': this.currentToken,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params: this.buildQueryParams(payload)
    }
  }


  /**
   * Generates the params for uploading the file(s)
   *
   * @returns API request params to upload the file(s)
   */
  uploadFiles(): IRequestParams<null | URLSearchParams> {

    return {
      url: `${this.environmentService.API_SERVICE_URL}/attachment/upload`,
      headers: {
        'token': this.currentToken
      },
      params: this.buildQueryParams(),
    }

  }

  /**
   * Generates the API request params to fetch media library
   *
   * @returns API request params to fetch media library
   */
  public getMediaAttachments(): IRequestParams<URLSearchParams> {

    return {
      url: `${this.environmentService.API_SERVICE_URL}/attachments`,
      headers: {
        'token': this.currentToken,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params: this.buildQueryParams()
    }
  }

  /**
   * Generates the API request params to delete the selected attachment
   *
   * @param payload query parameters
   *
   * @returns API request params to delete the selected attachment
   */
  public deleteAttachment(payload:IParams): IRequestParams<URLSearchParams> {

    return {
      url: `${this.environmentService.API_SERVICE_URL}/delete/attachment`,
      headers: {
        'token': this.currentToken,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params: this.buildQueryParams(payload)
    }
  }

  /**
   * Generates request params to add/create new page.
   *
   * @param pageData page data object
   *
   * @returns API request params to add/create new page.
   */
  public createPage(): IRequestParams<URLSearchParams> {

    return {
      url: `${this.environmentService.API_SERVICE_URL}/add/page`,
      headers: {
        'token': this.currentToken,
        'content-type': 'application/json',
      },
      params: this.buildQueryParams(),
    }

  }

  /**
   * Generates request params to add/create preview page.
   *
   * @param pageData page data object
   *
   * @returns API request params to add/create new page.
   */
  public createPagePreview(): IRequestParams<URLSearchParams> {

    return {
      url: `${this.environmentService.API_SERVICE_URL}/add/pagePreview`,
      headers: {
        'token': this.currentToken,
        'content-type': 'application/json',
      },
      params: this.buildQueryParams(),
    }

  }

  /**
   * Generates request params to delete a page.
   *
   * @param id id of the page
   *
   * @returns delete a page API request params.
   */
  public deletePage(id: string): IRequestParams<URLSearchParams> {

    const params = {
      id: id
    } as IParams;

    return {
      url: `${this.environmentService.API_SERVICE_URL}/delete/page`,
      headers: {
        'token': this.currentToken,
        'content-type': 'application/x-www-form-urlencoded',
      },
      params: this.buildQueryParams(params)
    }

  }

  /**
   * Generates request params to update a page.
   *
   * @param id id of the page
   * @param pageData page data object
   *
   * @returns API request params to update a page.
   */
  public updatePage(id:string): IRequestParams<URLSearchParams> {

    const params = {
      pageId: id
    } as IParams;

    return {
      url: `${this.environmentService.API_SERVICE_URL}/update/page`,
      headers: {
        'token': this.currentToken,
        'content-type': 'application/json',
      },
      params: this.buildQueryParams(params)
    }

  }

  /**
   * Generates request params to get a page by slug.
   *
   * @param slug slug of the page
   *
   * @returns API request params to get a page by slug.
   */
  public getPageBySlug(slug:string): IRequestParams<URLSearchParams> {

    const params = {
      slug: slug
    } as IParams;

    return {
      url: `${this.environmentService.API_SERVICE_URL}/page/slug`,
      headers: {
        'token': this.currentToken,
        'content-type': 'application/x-www-form-urlencoded',
      },
      params: this.buildQueryParams(params)
    }

  }

}
