import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UtilitiesService } from '../services/utilities.service';


@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  constructor(
    private utilitiesService: UtilitiesService
  ) { }

  /**
   * Identifies and handles a given HTTP request.
   *
   * @param request  outgoing request object to handle.
   * @param next interceptor in the chain, or the backend if no interceptors remain in the chain.
   * @returns An observable of the event stream.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.utilitiesService.loader.next(true);

    return next.handle(request).pipe(
      finalize(() => this.utilitiesService.loader.next(false))
    );

  }
}
