import { Component, OnInit } from '@angular/core';
import { IMediaAttachment } from '@cms/core/endpoints/models';
import { EnvironmentService } from '@cms/core/services';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { MediaLibraryService } from 'src/app/core/endpoints/services/media-library.service';

@Component({
  selector: 'cms-browse-image',
  templateUrl: './browse-image.component.html',
  styleUrls: ['./browse-image.component.scss']
})
export class BrowseImageComponent implements OnInit {

  public attachments: IMediaAttachment[] = [];

  public baseUrl: string = '';

  constructor(
    private environmentService: EnvironmentService,
    private mediaLibraryService: MediaLibraryService,
    private modal: NzModalRef,
    ) {

      this.baseUrl = this.environmentService.API_SERVICE_URL + '/download/attachment/';

     }

  ngOnInit(): void {

    this.getAttachments();

  }

  /**
   * This method will call the media library service to send a get attachments API request.
   *
   * @returns void
   */
  public getAttachments(): void {

    this.mediaLibraryService.getMediaAttachments().subscribe(response => {

      if (response && response.data) {

        this.attachments = response.data;

      }

    })

  }

  /**
   * Get attachment url.
   *
   * @param fileName name of the attachment.
   * @param extension extension of the attachment.
   *
   * @returns url
   */
  public getAttachmentUrl(fileName: string, extension: string): string {

    return `${this.baseUrl}${fileName}/${extension.replace('.','')}`;

  }

  public onFileSelect(url:string):void{

    this.modal.destroy(url);

  }

}
