import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@cms/core/services';

@Component({
  selector: 'cms-secured',
  templateUrl: './secured.component.html',
  styleUrls: ['./secured.component.scss']
})
export class SecuredComponent implements OnInit {

  isCollapsed = false;

  today:Date = new Date();

  constructor(private authService:AuthService, private router:Router) { }

  ngOnInit(): void {
  }

  /**
   * Logout user.
   */
  public onLogout(): void {

    this.authService.logoutUser();
    this.router.navigate(['/login']);

  }

}
