import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { addSectionControlOptions, IOption, AddSectionControlNames } from '@cms/shared/models';

@Component({
  selector: 'cms-content-blocks',
  templateUrl: './content-blocks.component.html',
  styleUrls: ['./content-blocks.component.scss']
})
export class ContentBlocksComponent implements OnInit {

  public showContentBlockControlList:boolean = false;

  public addSectionControlList: IOption<AddSectionControlNames>[] = addSectionControlOptions;

  @Output() sectionControlClick = new EventEmitter<{name:string, icon:string}>();

  constructor() {
   }

  ngOnInit(): void {
  }

  public sectionClick(controlName:string, icon:string):void{
    this.showContentBlockControlList = !this.showContentBlockControlList;
    this.sectionControlClick.emit({name:controlName,icon:icon});
  }

}
