<div class="control-container">
  <div class="control-title mb-3 fw-bold d-flex justify-content-between">
    <div>
      <i nz-icon [nzType]="'align-left'" nzTheme="outline" class="ant-btn-primary p-2 rounded-circle"></i>
      Card-Block{{ currentIndex > 0 ? ' - ' + currentIndex : ''}}
    </div>
    <div>
      <nz-button-group>
        <button *ngIf="currentIndex > 1" nz-button nzType="primary" nz-tooltip nzTooltipTitle="Move Up"
          nzTooltipPlacement="bottom" (click)="reIndexControl(currentIndex-1, currentIndex-2)"><i nz-icon
            nzType="arrow-up"></i></button>
        <button *ngIf="currentIndex < controlLength" nz-button nzType="primary" nz-tooltip nzTooltipTitle="Move Down"
          nzTooltipPlacement="bottom" (click)="reIndexControl(currentIndex-1, currentIndex)"><i nz-icon
            nzType="arrow-down"></i></button>
        <button *ngIf="controlLength > 1" nz-button nzType="primary" nz-tooltip nzTooltipTitle="Re-Index Position"
          nzTooltipPlacement="bottom" nz-popover nzPopoverTitle="" nzPopoverTrigger="click"
          [nzPopoverContent]="contentTemplate"><i nz-icon nzType="retweet"></i></button>
        <button nz-button nzType="primary" (click)="delete()"><i nz-icon nzType="delete"></i></button>
      </nz-button-group>
      <ng-template #contentTemplate>

        <nz-input-group nzSearch [nzAddOnAfter]="addOnAfterTemplate">
          <nz-input-number [(ngModel)]="moveTo" [nzMin]="1" [nzMax]="controlLength" [nzStep]="1"></nz-input-number>
        </nz-input-group>
      </ng-template>
      <ng-template #addOnAfterTemplate>
        <button nz-button nzType="primary" nzSearch (click)="reIndexControl(currentIndex-1, moveTo-1)">Move</button>
      </ng-template>
    </div>
  </div>
  <div class="control-body">
    <div [attr.id]="id">
      <form nz-form [formGroup]='cardsForm'>
        <div nz-row [nzGutter]="16">
          <div nz-col class="gutter-row" nzSpan="12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzFor="cardBlockHeader">Header</nz-form-label>
              <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Please enter block header!">
                <input nz-input formControlName="cardBlockHeader" id="cardBlockHeader" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col class="gutter-row" nzSpan="12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzFor="cardBlockHeaderBgColor">Header Background Color
              </nz-form-label>
              <nz-form-control [nzSm]="24" [nzXs]="24">
                <nz-avatar id="cardBlockHeaderBgColor" [nzGap]="4" [ngStyle]="{ 'background-color': cardBlockHeaderBackgroundColor }"
                  [nzText]="'Header'" nzSize="large" style="vertical-align: middle;cursor: pointer;"
                  [(colorPicker)]="cardBlockHeaderBackgroundColor" (colorPickerChange)="onChangeColor($event)">
                </nz-avatar>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col class="gutter-row" nzSpan="12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzFor="cardBlockColumns">Columns</nz-form-label>
              <nz-form-control [nzSm]="24" [nzXs]="24">
                <nz-select class="w-100" formControlName="cardBlockColumns" id="cardBlockColumns">
                  <nz-option nzValue="1" nzLabel="1"></nz-option>
                  <nz-option nzValue="2" nzLabel="2"></nz-option>
                  <nz-option nzValue="3" nzLabel="3"></nz-option>
                  <nz-option nzValue="4" nzLabel="4"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col class="gutter-row" nzSpan="12">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzFor="cardBlockBgColor">Card Background Color
              </nz-form-label>
              <nz-form-control [nzSm]="24" [nzXs]="24">
                <nz-avatar id="cardBlockBgColor" [nzGap]="4" [ngStyle]="{ 'background-color': cardBlockBackgroundColor }"
                  [nzText]="'Header'" nzSize="large" style="vertical-align: middle;cursor: pointer;"
                  [(colorPicker)]="cardBlockBackgroundColor" (colorPickerChange)="onChangeCardBlockBGColor($event)">
                </nz-avatar>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row [nzGutter]="16">
          <ng-container formArrayName="cards" *ngFor="let card of getCardsFormArrayControls(); let i = index">
            <ng-container [formGroupName]="i">
              <div nz-col class="gutter-row" [nzSpan]="12">
                <nz-card [nzTitle]="'Card-'+ (i+1)" [nzBodyStyle]="{'padding-bottom':'0'}" [nzExtra]="extraTemplate">
                  <div nz-row>
                    <div nz-col class="gutter-row" [nzSpan]="24">
                      <nz-form-item>
                        <nz-form-label [nzSpan]="24" [nzFor]="'imagePath'+i">Image Path
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24">
                          <nz-input-group nzSearch [nzAddOnAfter]="suffixBrowseImageButton">
                            <input nz-input formControlName="imagePath" [attr.id]="'imagePath'+i" />
                          </nz-input-group>

                          <ng-template #suffixBrowseImageButton>
                            <button nz-button nzType="primary" nzSearch (click)="browseMedia(i)"><i nz-icon
                                nzType="file-image"></i></button>
                          </ng-template>
                        </nz-form-control>
                      </nz-form-item>
                      <img *ngIf="getCardsFormArray.controls[i].get('imagePath')?.value" nz-image width="100px"
                        height="100px" nz-tooltip nzTooltipTitle="Click the image to preview"
                        [nzSrc]="getCardsFormArray.controls[i].get('imagePath')?.value" alt="" />
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="24">
                      <nz-form-item>
                        <nz-form-label [nzSpan]="24" [nzFor]="'header'+i">Card Header
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24">
                          <input nz-input formControlName="header" [attr.id]="'header'+i" />
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="24">
                      <nz-form-item>
                        <nz-form-label [nzSpan]="24" [nzFor]="'cardLink'+i">Card Link
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24">
                          <input nz-input formControlName="cardLink" [attr.id]="'cardLink'+i" />
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div nz-col class="gutter-row" [nzSpan]="24">
                      <nz-form-item>
                        <nz-form-label [nzSpan]="24" [nzFor]="'description'+i">Card Description
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24">
                          <textarea nz-input formControlName="description" [attr.id]="'description'+i"
                            [nzAutosize]="{ minRows: 2 }"></textarea>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <!-- <div nz-col class="gutter-row" nzSpan="24">
                      <nz-form-item>
                        <nz-form-label [nzSpan]="24" [nzFor]="'bgColor'+i">Background Color
                        </nz-form-label>
                        <nz-form-control [nzSm]="24" [nzXs]="24">
                          <nz-avatar [attr.id]="'bgColor'+i" [nzGap]="4" [ngStyle]="{ 'background-color': getCardsFormArray.controls[i].value.bgColor }"
                            [nzText]="'Card'" nzSize="large" style="vertical-align: middle;cursor: pointer;"
                            [(colorPicker)]="currentCardBGColor" (colorPickerChange)="onChangeCardColor($event, i)">
                          </nz-avatar>
                        </nz-form-control>
                      </nz-form-item>
                    </div> -->
                  </div>
                </nz-card>
                <ng-template #extraTemplate>
                  <button nz-button nzType="primary" (click)="removeCardGroup(i)"><i nz-icon
                      nzType="delete"></i></button>
                </ng-template>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div nz-row class="justify-content-center">
          <div nz-col [nzSpan]="12">
            <nz-card nzTitle="" [nzBodyStyle]="{'padding-top':'0','padding-bottom':'0'}">
              <div nz-row>
                <div nz-col [nzSpan]="24">
                  <button nz-button nzType="primary" nzBlock (click)="onAddMoreCard()">
                    <i nz-icon nzType="plus" nzTheme="outline"></i>
                    Add More Card
                  </button>
                </div>
              </div>
            </nz-card>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
