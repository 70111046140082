import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { environment } from 'src/environments/environment';


/**
 * A service for managing environment configurations.
 */
@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  /** Environment Configuration */
  public readonly BASE: string = environment.BASE;

  /** Environment Configuration */
  public readonly ENVIRONMENT: string = environment.ENVIRONMENT;

  /** Environment Configuration */
  public readonly API_SERVICE_URL: string = environment.API_SERVICE_URL;

  /** Environment Configuration */
  public readonly PORT: number = environment.PORT;

  /** Environment Configuration */
  public readonly FRONTEND_BASE_URL: string = environment.FRONTEND_BASE_URL;

  /**
   * A getter that gets the current locale.
   *
   * @returns the current locale
   */
  public get currentLocale(): string {
    return this.locale;
  }

  constructor(@Inject(LOCALE_ID) private locale: string) {
  }
}
