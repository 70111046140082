import { Component, Input, AfterViewInit, EventEmitter, Output, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import Vditor from 'vditor';
import { BrowseImageComponent } from '../../browse-image/browse-image.component';
import { IContentBlockAttributes, IReIndexControls } from '@cms/shared/models';

@Component({
  selector: 'cms-ctrl-content-block',
  templateUrl: './ctrl-content-block.component.html',
  styleUrls: ['./ctrl-content-block.component.scss']
})
export class CtrlContentBlockComponent implements AfterViewInit, OnInit {

  @Input() id: string = 'cmsContentBlockCtrl';

  @Input() value: string = '';

  @Input()
  set index(value: number) {

    this.currentIndex = value;
    this.moveTo = value;

  }

  @Input()
  set maxIndex(value: number) {

    this.controlLength = value;

  }

  @Output() deleteControl = new EventEmitter();

  @Output() blur = new EventEmitter<string>();

  @Output() reIndex = new EventEmitter<IReIndexControls>();

  public editor: Vditor = {} as Vditor;

  public moveTo: number = 0;

  public currentIndex: number = 0;

  public controlLength: number = 0;

  private contentHtmlParsed: string = '';
  public contentBlockHeaderText: string = '';
  public contentBlockHeaderBackgroundColor: string = '#8bc63e';
  public contentBlockHeaderTextColor: string = '#fff';

  /**
   * To maintain all subscriptions that have occurred for this component,
   * which will be destroyed when then component destroyed
   */
  private subscriptions: Subscription[] = [];

  constructor(
    private nzModalService: NzModalService,
  ) {
  }

  ngOnInit(): void {
    this.parseControlValues();
  }

  ngAfterViewInit(): void {
    this.initEditor();
  }

  private parseControlValues(): void {
    if (this.value) {
      let formValues: IContentBlockAttributes;
      try {
        formValues = JSON.parse(this.value) as IContentBlockAttributes;
      }
      catch (e) {

        formValues = {
          contentBlockHeaderText: this.contentBlockHeaderText,
          contentBlockHeaderBGColor: this.contentBlockHeaderBackgroundColor,
          contentBlockHeaderTextColor: this.contentBlockHeaderTextColor,
          content: this.value
        }

      }
      if (formValues) {

        if (formValues.content) this.contentHtmlParsed = formValues.content;

        if (formValues.contentBlockHeaderText) {
          this.contentBlockHeaderText = formValues.contentBlockHeaderText;
        }

        if (formValues.contentBlockHeaderBGColor) {
          this.contentBlockHeaderBackgroundColor = formValues.contentBlockHeaderBGColor;
        }

        if (formValues.contentBlockHeaderTextColor) {
          this.contentBlockHeaderTextColor = formValues.contentBlockHeaderTextColor;
        }
      }
    }
  }

  public onChangeHeaderText(): void {
    this.emitBlur();
  }

  public onChangeHeaderBgColor(color: string): void {
    this.contentBlockHeaderBackgroundColor = color;
    this.emitBlur();
  }

  public onChangeHeaderTextColor(color: string): void {
    this.contentBlockHeaderTextColor = color;
    this.emitBlur();
  }

  public initEditor(): void {

    this.editor = new Vditor(this.id, {
      minHeight: 450,
      lang: 'en_US',
      blur: this.emitBlur.bind(this),
      toolbarConfig: {
        pin: true,
      },
      cache: {
        enable: false,
      },
      after: () => {
        this.editor.setValue(this.editor.html2md(this.contentHtmlParsed));
        this.emitBlur();
      },
      toolbar: [
        "emoji",
        "headings",
        "italic",
        "bold",
        {
          name: 'image',
          hotkey: '⇧⌘I',
          tipPosition: 's',
          tip: 'Insert an image',
          icon: '<svg height="42px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="42px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M368,224c26.5,0,48-21.5,48-48c0-26.5-21.5-48-48-48c-26.5,0-48,21.5-48,48C320,202.5,341.5,224,368,224z"/><path d="M452,64H60c-15.6,0-28,12.7-28,28.3v327.4c0,15.6,12.4,28.3,28,28.3h392c15.6,0,28-12.7,28-28.3V92.3   C480,76.7,467.6,64,452,64z M348.9,261.7c-3-3.5-7.6-6.2-12.8-6.2c-5.1,0-8.7,2.4-12.8,5.7l-18.7,15.8c-3.9,2.8-7,4.7-11.5,4.7   c-4.3,0-8.2-1.6-11-4.1c-1-0.9-2.8-2.6-4.3-4.1L224,215.3c-4-4.6-10-7.5-16.7-7.5c-6.7,0-12.9,3.3-16.8,7.8L64,368.2V107.7   c1-6.8,6.3-11.7,13.1-11.7h357.7c6.9,0,12.5,5.1,12.9,12l0.3,260.4L348.9,261.7z"/></g></svg>',
          click: this.browseMedia.bind(this),
        },
        "strike",
        "link",
        "|",
        "list",
        "ordered-list",
        "check",
        "outdent",
        "indent",
        "|",
        "quote",
        "line",
        "code",
        "inline-code",
        "insert-before",
        "insert-after",
        "|",
        "record",
        "table",
        "|",
        "undo",
        "redo",
        "|",
        "fullscreen",
        "edit-mode",
        {
          name: "more",
          toolbar: [
            "both",
            "code-theme",
            "content-theme",
            "export",
            "outline",
            "preview",
            "devtools",
            "info",
            "help",
          ],
        },
      ]
    });

  }

  public emitBlur(): void {
    const data: IContentBlockAttributes = {
      contentBlockHeaderText: this.contentBlockHeaderText,
      contentBlockHeaderBGColor: this.contentBlockHeaderBackgroundColor,
      contentBlockHeaderTextColor: this.contentBlockHeaderTextColor,
      content: this.editor.getHTML()
    }
    this.blur.emit(JSON.stringify(data));
  }

  public delete(): void {
    this.deleteControl.emit();
  }

  private insertImageMarkDown(url: string): void {

    const text: string = `![](${url || 'http://'})`;
    this.editor.insertValue(text);
  }

  public browseMedia(): void {

    const nzAfterCloseEventEmitter = new EventEmitter<any>()

    const nzAfterCloseSubscription = nzAfterCloseEventEmitter.subscribe((url: string) => {

      if (url) {

        this.insertImageMarkDown(url);

      }

    })

    this.nzModalService.create({
      nzTitle: 'Browse Image',
      nzContent: BrowseImageComponent,
      nzFooter: null,
      nzStyle: { width: '100%', 'max-width': '765px' },
      nzAfterClose: nzAfterCloseEventEmitter,
    });

    this.subscriptions.push(nzAfterCloseSubscription);

  }

  public reIndexControl(currentPosition: number, moveTo: number): void {
    const reIndexValues: IReIndexControls = { currentPosition: currentPosition, moveTo: moveTo };
    this.reIndex.emit(reIndexValues);
  }

  public ngOnDestroy(): void {

    this.subscriptions.forEach(subscription => subscription.unsubscribe());

  }

}
