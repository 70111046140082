import { NgModule } from '@angular/core';
import { BrowserModule,Title } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';

import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { SharedModule } from '@cms/shared/module';
import { CoreModule } from '@cms/core/module';
import { LoadingInterceptor } from '@cms/core/interceptors';
import { UtilitiesService } from './core/services/utilities.service';
import { ErrorInterceptor } from '@cms/core/interceptors';
import { SecuredComponent } from './layouts/secured/secured.component';
import { PublicComponent } from './layouts/public/public.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/** config angular i18n **/
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
registerLocaleData(en);

/** config ng-zorro-antd i18n **/
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';


@NgModule({
  declarations: [
    AppComponent,
    SecuredComponent,
    PublicComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CoreModule,
    SharedModule,

    NzGridModule,
    NzLayoutModule,
    NzMenuModule,
    NzIconModule,
  ],
  providers: [
    UtilitiesService,Title,
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: NZ_I18N, useValue: en_US }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
