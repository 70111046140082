<div nz-row>
  <div nz-col nzSpan="24" class="d-flex justify-content-center py-5">
    <div class="content-block-add-button-container position-relative">
      <button nz-button nzType="primary" [nzSize]="'large'" nzShape="circle" (click)="showContentBlockControlList = !showContentBlockControlList">
        <i nz-icon nzType="plus" *ngIf="!showContentBlockControlList"></i>
        <i nz-icon nzType="close" *ngIf="showContentBlockControlList"></i>
      </button>
      <span class="d-none fw-bold position-absolute" *ngIf="!showContentBlockControlList">ADD TO SECTION</span>
    </div>
  </div>
  <div *ngIf="showContentBlockControlList" nz-col nzSpan="24" class="shadow-lg w-100 bg-light-blue p-4 justify-content-center">
    <div class="content-block bg-white" [ngClass]="i < addSectionControlList.length ? 'me-2':''" *ngFor="let control of addSectionControlList;let i = index" (click)="sectionClick(control.value, control.icon)">
      <div class="d-block"><i nz-icon [nzType]="(control.icon)" nzTheme="outline" class="ant-btn-primary p-2 rounded-circle"></i></div>
      <div class="d-block">{{control.label}}</div>
    </div>
  </div>
</div>
