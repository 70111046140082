import { Component, Input, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ICardBlock, IReIndexControls } from '@cms/shared/models';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import { BrowseImageComponent } from '../../browse-image/browse-image.component';

@Component({
  selector: 'cms-ctrl-card-block',
  templateUrl: './ctrl-card-block.component.html',
  styleUrls: ['./ctrl-card-block.component.scss']
})
export class CtrlCardBlockComponent implements OnInit, OnDestroy {

  @Input() id: string = 'cmsContentBlockCtrl';

  @Input() value: string = '';

  @Input()
  set index(value: number){

    this.currentIndex = value;
    this.moveTo = value;

  }

  @Input()
  set maxIndex(value: number){

    this.controlLength = value;

  }

  @Output() deleteControl = new EventEmitter();

  @Output() blur = new EventEmitter<string>();

  @Output() reIndex = new EventEmitter<IReIndexControls>();

  public cardsForm: FormGroup;

  public moveTo: number = 0;

  public currentIndex:number = 0;

  public controlLength:number = 0;

  public cardBlockHeaderBackgroundColor:string = '#8bc63e';
  public cardBlockBackgroundColor:string = '#eaf5db';

  public currentCardBGColor:string = '#eaf5db';

  /**
   * To maintain all subscriptions that have occurred for this component,
   * which will be destroyed when then component destroyed
   */
  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private nzModalService: NzModalService,
  ) {

    this.cardsForm = this.formBuilder.group({
      cardBlockHeader: [null],
      cardBlockHeaderBgColor: [''],
      cardBlockBgColor: [''],
      cardBlockColumns: ['1'],
      cards: this.formBuilder.array(this.generateCardsForm())
    })

  }

  ngOnInit(): void {

    const cardsFormSubscription = this.cardsForm.valueChanges.subscribe(value => {

      if (value) {

        this.blur.emit(JSON.stringify(value));

      }

    });

    this.subscriptions.push(cardsFormSubscription);

    this.parseControlValues();

  }

  public delete(): void {
    this.deleteControl.emit();
  }

  public emitBlur(): void {
    this.blur.emit('');
  }

  public generateCardsForm(): FormGroup[] {

    const controls: FormGroup[] = [];
    const columns = 1;

    if (columns > 0) {

      for (let index = 0; index < columns; index++) {

        controls.push(
          this.formBuilder.group({
            imagePath: [null, [Validators.required]],
            header: [null],
            cardLink: [null],
            description: [null],
            bgColor:['']
          })
        )

      }

    }

    return controls;
  }

  get getCardsFormArray(): FormArray {

    return <FormArray>this.cardsForm.get('cards');

  }

  /** Gets the controls */
  public getCardsFormArrayControls(): AbstractControl[] {

    return (this.cardsForm.get('cards') as FormArray).controls;

  }

  public onColumnChanged(count: string): void {

    const controls: FormGroup[] = [];
    const columns = Number(count);

    this.cardsForm.setControl('cards', this.formBuilder.array([]));

    if (columns > 0) {

      for (let index = 0; index < columns; index++) {

        controls.push(
          this.formBuilder.group({
            imagePath: [null, [Validators.required]],
            header: [null],
            cardLink: [null],
            description: [null],
            bgColor:[null]
          })
        )

      }

      this.cardsForm.setControl('cards', this.formBuilder.array(controls));

    }

  }

  public pushCardGroup(): FormGroup {

    return this.formBuilder.group({
      imagePath: [null, [Validators.required]],
      header: [null],
      cardLink: [null],
      description: [null],
      bgColor:[null]
    });

  }

  public parseControlValues(): void {

    if (this.value) {

      const formValues = JSON.parse(this.value) as ICardBlock;

      if (formValues) {

        if (formValues.cardBlockHeader) this.cardsForm.controls['cardBlockHeader'].setValue(formValues.cardBlockHeader);

        if (formValues.cardBlockHeaderBgColor) {
          this.cardsForm.controls['cardBlockHeaderBgColor'].setValue(formValues.cardBlockHeaderBgColor);
          this.cardBlockHeaderBackgroundColor = formValues.cardBlockHeaderBgColor;
        }

        if (formValues.cardBlockBgColor) {
          this.cardsForm.controls['cardBlockBgColor'].setValue(formValues.cardBlockBgColor);
          this.cardBlockBackgroundColor = formValues.cardBlockBgColor;
        }

        if (formValues.cardBlockColumns) this.cardsForm.controls['cardBlockColumns'].setValue(formValues.cardBlockColumns);

        if (formValues.cards && formValues.cards.length > 0) {


          const controls: FormGroup[] = [];

          this.cardsForm.setControl('cards', this.formBuilder.array([]));

          formValues.cards.forEach(card => {

            controls.push(
              this.formBuilder.group({
                imagePath: [card.imagePath, [Validators.required]],
                header: [card.header],
                cardLink: [card.cardLink],
                description: [card.description],
                bgColor:[card.bgColor]
              })
            )

          });

          this.cardsForm.setControl('cards', this.formBuilder.array(controls));

        }

      }

    }

  }

  public onAddMoreCard(): void {

    this.getCardsFormArray.push(this.pushCardGroup());

  }

  public removeCardGroup(index: number): void {

    this.getCardsFormArray.removeAt(index);

  }

  public browseMedia(controlIndex: number): void {

    const nzAfterCloseEventEmitter = new EventEmitter<any>()

    const nzAfterCloseSubscription = nzAfterCloseEventEmitter.subscribe((url: string) => {

      if (url) {

        const formArray = this.getCardsFormArray;

        if (formArray) {

          formArray.controls[controlIndex].get('imagePath')?.patchValue(url);

        }

      }

    })

    this.nzModalService.create({
      nzTitle: 'Browse Image',
      nzContent: BrowseImageComponent,
      nzFooter: null,
      nzStyle: { width: '100%', 'max-width': '765px' },
      nzAfterClose: nzAfterCloseEventEmitter,
    });

    this.subscriptions.push(nzAfterCloseSubscription);

  }

  public reIndexControl(currentPosition: number, moveTo: number): void {
    const reIndexValues: IReIndexControls = { currentPosition: currentPosition, moveTo: moveTo };
    this.reIndex.emit(reIndexValues);
  }


  public onChangeColor(color: string): void {
    this.cardBlockHeaderBackgroundColor = color;
    this.cardsForm.controls['cardBlockHeaderBgColor'].patchValue(color);
  }


  public onChangeCardBlockBGColor(color: string): void {
    this.cardBlockBackgroundColor = color;
    this.cardsForm.controls['cardBlockBgColor'].patchValue(color);
  }


  public onChangeCardColor(color: string, controlIndex:number): void {

    const formArray = this.getCardsFormArray;

    if (formArray) {

      formArray.controls[controlIndex].get('bgColor')?.patchValue(color);

    }

  }

  public ngOnDestroy(): void {

    this.subscriptions.forEach(subscription => subscription.unsubscribe());

  }

}
