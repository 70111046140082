import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IListControl, IReIndexControls } from '@cms/shared/models';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cms-ctrl-list-block',
  templateUrl: './ctrl-list-block.component.html',
  styleUrls: ['./ctrl-list-block.component.scss']
})
export class CtrlListBlockComponent implements OnInit, OnDestroy {

  @Input() id: string = 'cmsContentBlockCtrl';

  @Input() value: string = '';

  @Input()
  set index(value: number){

    this.currentIndex = value;
    this.moveTo = value;

  }

  @Input()
  set maxIndex(value: number){

    this.controlLength = value;

  }

  @Output() deleteControl = new EventEmitter();

  @Output() blur = new EventEmitter<string>();

  @Output() reIndex = new EventEmitter<IReIndexControls>();


  public listForm: FormGroup;

  public moveTo: number = 0;

  public currentIndex:number = 0;

  public controlLength:number = 0;

  public listBlockHeaderBackgroundColor:string = '#8bc63e';
  public listBlockHeaderTextColor:string = '#fff';


  /**
   * To maintain all subscriptions that have occurred for this component,
   * which will be destroyed when then component destroyed
   */
  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private nzModalService: NzModalService,
  ) {

    this.listForm = this.formBuilder.group({
      listBlockHeader: [null],
      listBlockHeaderBgColor: [''],
      listBlockHeaderTextColor:[''],
      items: this.formBuilder.array(this.generateListForm())
    });

    this.listForm.controls['listBlockHeaderBgColor'].setValue(this.listBlockHeaderBackgroundColor);
    this.listForm.controls['listBlockHeaderTextColor'].setValue(this.listBlockHeaderTextColor);

  }

  ngOnInit(): void {

    const cardsFormSubscription = this.listForm.valueChanges.subscribe(value => {

      if (value) {

        this.blur.emit(JSON.stringify(value));

      }

    });

    this.subscriptions.push(cardsFormSubscription);

    this.parseControlValues();
  }

  public delete(): void {
    this.deleteControl.emit();
  }

  public emitBlur(): void {
    this.blur.emit('');
  }

  public parseControlValues(): void {

    if (this.value) {

      const formValues = JSON.parse(this.value) as IListControl;

      if (formValues) {

        if (formValues.listBlockHeader) this.listForm.controls['listBlockHeader'].setValue(formValues.listBlockHeader);

        if (formValues.listBlockHeaderBgColor) {
          this.listForm.controls['listBlockHeaderBgColor'].setValue(formValues.listBlockHeaderBgColor);
          this.listBlockHeaderBackgroundColor = formValues.listBlockHeaderBgColor;
        }

        if (formValues.listBlockHeaderTextColor) {
          this.listForm.controls['listBlockHeaderTextColor'].setValue(formValues.listBlockHeaderTextColor);
          this.listBlockHeaderTextColor = formValues.listBlockHeaderTextColor;
        }

        if (formValues.items && formValues.items.length > 0) {


          const controls: FormGroup[] = [];

          this.listForm.setControl('items', this.formBuilder.array([]));

          formValues.items.forEach(item => {

            controls.push(
              this.formBuilder.group({
                title: [item.title],
                link: [item.link]
              })
            )

          });

          this.listForm.setControl('items', this.formBuilder.array(controls));

        }

      }

    }

  }

  public reIndexControl(currentPosition: number, moveTo: number): void {
    const reIndexValues: IReIndexControls = { currentPosition: currentPosition, moveTo: moveTo };
    this.reIndex.emit(reIndexValues);
  }

  public onChangeHeaderBgColor(color: string): void {
    this.listBlockHeaderBackgroundColor = color;
    this.listForm.controls['listBlockHeaderBgColor'].patchValue(color);
  }

  public onChangeHeaderTextColor(color: string): void {
    this.listBlockHeaderTextColor = color;
    this.listForm.controls['listBlockHeaderTextColor'].patchValue(color);
  }

  public generateListForm(): FormGroup[] {

    const controls: FormGroup[] = [];

    controls.push(
      this.formBuilder.group({
        title: [null],
        link: [null],
      })
    )

    return controls;
  }

  get getListFormArray(): FormArray {

    return <FormArray>this.listForm.get('items');

  }

  /** Gets the controls */
  public getListFormArrayControls(): AbstractControl[] {

    return (this.listForm.get('items') as FormArray).controls;

  }

  public pushListItem(): FormGroup {

    return this.formBuilder.group({
      title: [null],
      link: [null],
    });

  }

  public onAddMoreListItems(): void {

    this.getListFormArray.push(this.pushListItem());

  }

  public removeListItem(index: number): void {

    this.getListFormArray.removeAt(index);

  }

  public ngOnDestroy(): void {

    this.subscriptions.forEach(subscription => subscription.unsubscribe());

  }

}
