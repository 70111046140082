import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { throwIfAlreadyLoaded } from '@cms/shared/guards';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  exports: [
    NzLayoutModule,
    NzNotificationModule,
    NzButtonModule,
  ],
})
export class CoreModule {

  constructor(
    @Optional()
    @SkipSelf()
    parentModule?: CoreModule
  ) {

    throwIfAlreadyLoaded(parentModule, 'CoreModule');

  }

}
