
<div nz-row>
  <div nz-col nzSpan="24" class="shadow-sm p-3 bg-white">
    <nz-list nzGrid>
      <div nz-row nzGutter="20">
        <div nz-col *ngFor="let file of attachments; let i = index">
          <nz-list-item>
            <div class="card" (click)="onFileSelect(getAttachmentUrl(file.hash, file.ext))">
              <ng-container *ngTemplateOutlet="coverTemplate"></ng-container>
              <div class="card-info d-none">
                <ng-template #copyIcon>
                  <i nz-icon nzType="copy"></i>
                </ng-template>
              </div>
              <div class="card-profile">
                <ng-container *ngTemplateOutlet="cardDescription"></ng-container>
              </div>
            </div>
            <ng-template #coverTemplate>
              <div class="card-display" style="background-image: url('{{ getAttachmentUrl(file.hash, file.ext) }}');"></div>
            </ng-template>
            <ng-template #cardDescription>
              <div class="card-profile__text">
                <div nz-typography nzEllipsis>{{file.name}}</div>
                <div class="text-uppercase">{{file.mime}}</div>
              </div>
            </ng-template>
          </nz-list-item>
        </div>
      </div>
    </nz-list>
  </div>
</div>
